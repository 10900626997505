.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0,0,0,0);
	border: 0;
}

body {
	margin: 0;
	font-family: 'Helvetica', "Roboto Thin", -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Segoe UI', 'Oxygen',
	'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
	sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #121518;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
	monospace;
}

.btn-close {
    position: absolute;
    top: 25px;
    right: 25px;
}

.nav, .nav:hover{
	text-decoration: none;
	color: #060827;
	font-size: 14px;
	font-weight: bold;
	padding: 0 4px;
	margin: 0 12px;
	letter-spacing: 0.09rem;
	text-transform: capitalize;
}

button.nav{
	border: 0 none;
	background-color: transparent;
	cursor: pointer;
}
.hero-section-wrapper {
	background-color: #6bacc3;
}
.address-search-wrapper {
	display: block;
}
.address-search-wrapper button {
	margin-top: 12px;
}
.sn-rep-list-wrapper > div {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
}
.sn-pages-list {
	margin-top: 12px;
}
.nav-list {
	padding: 12px;
}
.nav-list li {
	list-style: none;
	padding: 8px;
	max-width: 200px;
	border-radius: 24px;
	text-align: center;
	margin: auto;
	display: inline-block;
	width: 50%;
}
.nav-list li.active{
	background-color: #f39b5f;
	color:#fff;
	border: 2px solid #bc8741;

}
.nav-list li.active a{
	color:#fff;

}
.nav-list li a {
	text-decoration: none;
	padding: 4px;
	color: #787889;
	font-size: 18px;
	font-weight: bold;
}

.sn-rep-list-section {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	margin: 32px auto;
}
.sn-rep-list-section div {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 40px;
}
.sn-rep-list-section div img {
	width: 150px;
	height: 120px;
	border: 1px solid #48afd8;
	padding: 12px;
	margin-bottom: 12px;
}
.sn-rep-list-section div a {
	border: 1px solid #266585;
	border-radius: 24px;
	padding: 8px 32px;
	text-decoration: none;
	color:#171a1d;
}
#autocomplete-wrapper{
	display: block;
	align-items: center;
	background-color: #fff;
	border: 1px solid #000;
	border-radius: 24px;
	padding: 4px;
	position: relative;
	width: 400px;
}

#autocomplete-wrapper .rbt-aux{
	position: absolute;
    top: 9px;
    right: 14px;
    opacity: .3;
}

.autocomplete-address-row{
	display: flex;
	justify-content: center;
	align-items: center;
}
.best-offers-section {
	background-color: #eea640;
}
.utility-select{
	margin-left: 12px;
}
.utility-select .dropdown-toggle{
	border: 1px solid #000 !important;
	border-radius: 24px !important;
	color: #000 !important;
	height: 52px !important;
}

.utility-select .dropdown-toggle{
    background-color: #fff;
    border: 2px solid #ccc;
    border-radius: 2px;
	padding-left: 20px;
	padding-right: 20px;
	height: 54px;
}

#autocomplete-wrapper input{
	background-color: transparent;
	border: 0 none;
	padding: 0 16px;
	font-size: 12px;
}

#autocomplete-wrapper input:focus{
	box-shadow: none;
}

#autocomplete-wrapper>div{
	flex: 1;
}

#autocomplete-wrapper .dropdown-menu{
	top: 8px !important;
}

#autocomplete-wrapper .dropdown-item{
	padding: 8px 16px;
}

#autocomplete-wrapper .dropdown-item mark{
	background-color: #F3F6FD;
	border-radius: 2px;
	padding: 0 4px;
}

.plan-saving{
	display: inline-block;
	margin: 4px auto 8px;
	font-size: 13px;
	font-weight: 500;
	border: 1.5px solid #3D96EE;
	padding: 2px 6px;
	border-radius: 2px;
	color: #3D96EE;
}

.plan-type-options{
	position: relative;
	bottom: -1px;
}

.plan-type-options .nav-tabs{
	border-bottom: 0 none;
}

.side-modal-body{
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	background-color: #fff;
	max-width: 100%;
	width: 60%;
	margin: 0 auto;
	padding: 88px 44px 44px;
	z-index: 10;
    overflow-y: auto;
	-webkit-overflow-scrolling: touch;
}

.form-control.is-valid, .was-validated .form-control:valid{
    border-color: #ced4da;
    background-image: none;
}

#agreement-wrapper{
	border: 1px solid #ddd;
	padding: 4px 24px;
    max-height: 700px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

#agreement-wrapper table,#agreement-wrapper td{
	position: static;
}

.form-group{
	margin: 20px 0;
}

.page-wrapper{
	position: relative;
}

.page-wrapper>div{
	position: absolute;
	width: 100%;
}

#plans-wrapper{
	display: flex;
	padding-top: 20px;
}

#plans-aside{
	max-width: 480px;
	min-width: 350px;
	width: 40%;
	padding: 24px 32px;
	flex-shrink: 0;
}

#plans-list{
	padding: 24px 32px;
	flex: 1;
	overflow: hidden;
}

.plan-compare-btn, .plan-compare-btn:hover{
	background-color: #fff;
	border: 1px solid #ddd;
}

.plan-compare-btn.active{
	background-color: #2562EC;
	color: #fff;
	border: 1px solid #2562EC;
}

.plans-filter-options{
	display: flex;
	flex-wrap: wrap;
}

.plan-row-wrapper{
	background-color: #fff;
	border: 1px solid #ddd;
	border-radius: 2px;
	margin: 20px 0;
	box-shadow: 0 3px 8px rgba(0, 0, 0, .03);
	overflow: hidden;
	transition: border-color .5s;
}

.plan-row-wrapper:hover{
	border-color: #999;
}

.plan-row{
	display: flex;
	padding: 0 16px;
}

.checkbox-item{
	display: flex;
	align-items: center;
	padding: 4px 12px;
	cursor: pointer;
	margin: 8px 0;
	flex: 0 0 50%;
	overflow: hidden;
}

.checkbox-item span{
	margin-left: 8px;
	color: #666;
	font-size: 15px;
	line-height: 1.1;
}

.checkbox-item span a{
	color: #666;
}

.checkbox-item.onlyread{
	opacity: .4;
	cursor: default;
}

.checkbox-item.full{
	flex: 0 0 100%;
}

.plans-filter-title{
	font-weight: 500;
	font-size: 17px;
	margin-bottom: 8px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.rep-logo{
	width: 80px;
	height: 42px;
}

.rep-logo i{
	display: inline-block;
	font-size: 11px;
	color: #999;
	font-style: normal;
	line-height: 1;
}

.rep-logo img{
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.plan-overview{
	display: flex;
	align-items: center;
}

.plan-info-group{
	display: flex;
	align-items: center;
	flex: 1;
}

.plan-info{
	flex: 1;
	text-align: center;
}

.plan-info>div{
	font-size: 18px;
	font-weight: 500;
	margin-bottom: -4px;
}

.plan-info label{
	font-size: 14px;
	color: #666;
	line-height: 1.2;
}

.plan-content{
	flex: 1;
	padding: 20px 0;
}

.plan-options{
	width: 200px;
	padding-top: 20px;
	flex-shrink: 0;
	border-left: 1px solid #ddd;
	margin-right: -16px;
	margin-left: 16px;
}

.plan-name{
	font-size: 15px;
	font-weight: 500;
	line-height: 1.3;
}

.plan-info .plan-est-rate{
	color: #3D96EE;
	font-size: 22px;
}

.plan-links{
	display: flex;
}

.plan-links a{
	display: block;
	margin-right: 24px;
	color: #666;
}

.plan-detail{
	max-height: 0;
	overflow: hidden;
	padding: 0 20px;
	font-size: 15px;
	background-color: #fbfbfb;
}

.plan-detail.active{
	max-height: 9999px;
}

.mask{
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba(0, 0, 0, .4);
}

.usage-adjust-row{
	display: flex;
	flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.usage-adjust-item{
	flex: 0 0 25%;
}

.usage-adjust-item .form-label{
	width: 100%;
	text-align: center;
	font-size: 13px;
	font-weight: 500;
	margin-bottom: 4px;
}

.usage-adjust-item .form-control{
	padding: 16px 4px;
	text-align: center;
    font-size: 14px;
	border: 0 none;
	background-color: #f8f9fa;
}

.premise-detail-row{
	margin-top: 12px;
	padding: 12px 16px;
	background-color: #fafafa;
}

.premise-detail{
	display: flex;
}

.premise-detail-item{
	font-size: 14px;
	color: #666;
	font-weight: 500;
	margin: 4px 0;
	flex: 1;
}

.checkbox-row .checkbox-item{
	align-items: flex-start;
}

.checkbox-row .checkbox-item span{
	position: relative;
	top: -2px;
    margin-left: 12px;
}

.checkbox-row .checkbox-item span.error{
	color: #dc3545;
}

.nav-link, .nav-link:focus, .nav-link:hover{
	color: #666;
}

.nav-tabs .nav-link.active{
	border-top: 2px solid #2562EC;
}

#plans-aside .plans-filter-dd, #plans-aside .plans-filter-dd button{
	width: 100%;
}

.plans-filter-dd button{
	text-align: left;
	position: relative;
	padding-right: 32px;
}
.enroll-select button{
	height: 52px;
	border-radius: 24px;
	color: #212529;
	border: 1px solid #266585 !important;
}
.enroll-select button::after{
	position: absolute;
	top: 25px !important;
	right: 15px !important;
}
.enroll-select button:focus:not(:focus-visible){
	outline: none;
	border-width: 1px;
}
.plans-filter-dd button::after{
	position: absolute;
	top: 16px;
	right: 10px;
}

.plans-filter-dd .dropdown-menu{
	width: 100%;
	max-height: 300px;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
}

.plans-filter-dd .dropdown-item{
	padding: 8px 16px;
	white-space: break-spaces;
}

.dropdown-item.active, .dropdown-item:active{
	background: #2562EC;
}

.page-link, .page-link:hover{
	color: #666;
}

.page-item.active .page-link{
	color: #fff;
	background-color: #2562EC;
	border-color: #2562EC;
}

.chart-tooltip{
	background-color: #fff;
	border: 1px solid #eee;
	padding: 16px 20px;
	border-radius: 2px;
	text-align: left;
	box-shadow: 0 3px 15px rgba(0, 0, 0, .15);
}

.chart-tooltip .date{
	font-size: 11px;
	color: #666;
}

.chart-tooltip .value{
	font-size: 16px;
	margin-top: 8px;
	font-weight: bold;
	color: #060827;
}

.modal-header button.close{
	background-color: transparent;
	border: 0 none;
	font-size: 26px;
	font-weight: 500;
	color: #999;
    position: absolute;
    top: 30px;
    right: 30px;
}

.access-btn{
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: center;
	font-size: 13px;
	font-weight: 600;
	background: #eea640;
	padding: 8px 16px;
	margin-top: 8px;
	white-space: nowrap;
	border: 0 none;
	border-radius: 2px;
	color: #fff;
}

.plan-tags span{
	font-size: 11px;
	font-weight: 500;
	color: #777;
	background-color: #f9f9f9;
	border-radius: 3px;
	display: inline-block;
	padding: 2px 6px;
	margin-right: 8px;
	margin-bottom: 4px;
    text-transform: uppercase;
}

.plan-detail-heading{
	font-size: 17px;
	margin-bottom: 8px;
	font-weight: 700;
}

.breakdown-table-wrapper{
	overflow-x: scroll;
	-webkit-overflow-scrolling: touch;
	margin-bottom: 24px;
	margin-left: -20px;
	margin-right: -20px;
	padding: 0 20px;
}

.breakdown-table-wrapper table{
	border: 1px solid #ddd;
}

.breakdown-table-wrapper td, .breakdown-table-wrapper th{
    text-align: center;
    padding: 12px 8px;
	font-size: 13px;
}

.breakdown-table-wrapper td.disabled, .breakdown-table-wrapper th.disabled{
    background-color: #f9f9f9;
    color: #ccc;
}

.breakdown-table-wrapper th{
	background-color: #f9f9f9;
}

.breakdown-table-wrapper td{
	background-color: #fff;
}

#plans-filter-toggle, #plans-filter-group .close-btn{
	display: none;
}

#plans-filter-group{
	margin-top: 32px;
}

.plans-filter{
	margin-bottom: 32px;
}

.get-usage-row{
	background-color: #f8f9fa;
	padding: 16px 20px;
	border-radius: 2px;
	border: 1px solid #ddd;
}

#term-filter-wrapper{
	margin-right: 12px;
}

#get-usage-option-wrapper{
	display: flex;
	flex-direction: column;
	margin-top: 12px;
}

.get-usage-option{
	cursor: pointer;
	border: 1px solid #ddd;
	border-radius: 2px;
	flex: 1;
	padding: 16px 20px;
	margin: 8px 0;
	display: flex;
	align-items: center;
	transition: border .3s;
	position: relative;
}

.get-usage-option h5{
	font-size: 17px;
}

.get-usage-option:hover{
	border-color: #999;
}

.get-usage-option p{
	margin-bottom: 0;
	font-size: 15px;
	line-height: 1.2;
}

.get-usage-option i{
	display: inline-block;
	font-style: normal;
	position: absolute;
	top: 3px;
	right: 3px;
	border-radius: 2px;
	padding: 1px 8px;
	font-size: 13px;
	font-weight: 600;
	background: #2562EC;
	color: #fff;
}

.get-usage-option.highlight{
	border-color: #2562EC;
	background-color: #F3F6FD;
	padding-top: 44px;
	padding-bottom: 44px;
}

.divider{
	text-align: center;
	border-bottom: 1px dotted #ccc;
	margin: 24px auto 36px;
	max-width: 540px;
}

.divider span{
	position: relative;
	font-size: 14px;
	top: 12px;
	color: #999;
	background-color: #fff;
	padding: 0 24px;
}

.container-md{
	max-width: 640px;
	margin: 44px auto 64px;
}

/* custom model */
.modal-header{
	border-bottom: 0 none;
	text-align: center;
	display: block;
}

.modal-title{
	margin-top: 32px;
}

.modal-header button.close{
	position: absolute;
	top: 16px;
	right: 20px;
}

.modal-content{
	border-radius: 2px;
    border: 0 none;
}

.modal-body{
	padding-left: 28px;
	padding-right: 28px;
}

/* fix react datepicker arrow position */
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle{
	left: 50% !important;
    transform: translate3d(-50%, 0px, 0px) !important;
}

.react-datepicker__navigation{
	top: 8px !important;
}

.tooltip-mark{
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	display: inline-block;
	width: 18px;
	height: 18px;
	line-height: 18px;
	text-align: center;
	border-radius: 50%;
	background-color: #ccc;
	color: #fff;
	cursor: default;
}

.tooltip{
	max-width: 240px;
	font-weight: 500;
}

.plan-options button{
	font-size: 15px;
	width: 100%;
	border-radius: 0;
}

#watch-video-poster{
	padding: 1px;
}


.best-offers-wrapper {
	display: flex;
	flex-direction: column;
	min-height:400px;
}
.content-best-offers-section {
	flex:100%;
	padding: 12px 12px 12px 0;
	margin: 0 12px 12px 0;
}
.content-best-offers-section p {
	font-size: 12px;
}
.img-best-offers-section {
	position: relative;
	margin: auto;
}
.best-offers-images img {
	max-width: 100%;
}
.best-offers-images {
	position: relative;
}
.sn-rep-list-section div img {
	width: 220px;
	height: 180px;
}
.best-offers-images.top {
	position: relative;
	width: 250px;
}

.best-offers-images:nth-child(2) {
	display: none;
}

.best-offers-images:nth-child(3) {
	display: none;
}
.content-section {
	padding: 12px;
	border-radius: 24px;
	margin-left: 12px;
	background-color: #eea640;
	color: #121518;
	box-shadow: 5px 9px 8px 10px #f3f3f3;
}

.footer-link a {
	color: #121518;
	text-decoration: none;
}

.read-more-links {
	text-align: center;
	margin-top: 28px;
}

.validate-address-section {
	padding: 32px;
	text-align: center;
}
.validate-address-section h2 {
	font-weight: bold;
}
.more-strategies-form-wrapper {
	margin: auto;
	padding: 32px 0;
	max-width: 85%;
	text-align: center;
}
.more-strategies-form-wrapper p {
	text-align: left;
}
.contact-us-text {
	margin: 24px auto;
	text-align: center;
	max-width: 85%;
}
.contact-us-text p {
	font-size: 16px;
	color: #000;
	font-weight: 600;
}
.contact-us-text p.footer-text {
	font-weight: 500;
}
.best-offers-save-wrapper {
	padding: 24px 0;
	max-width: 100%;
	text-align: center;
	margin: auto;
}
.best-offers-save-wrapper h2 {
	color: #266585;
	font-weight: bold;
}
.best-offers-main-wrapper {
	background: linear-gradient(to bottom, #eea640 61%, #ffffff 29%);
}
.container {
	max-width: 95%;
	margin: auto;
}
input.form-control {
	padding: 14px 14px;
	border-radius: 24px;
	border: 1px solid #266585;
}
input::placeholder {
	text-align: center;
	color: #121518;
	font-weight: bold;

}
input.form-control:hover {
	border-color: #eea640;
}

input.form-control:focus {
	border-color: #11a85d;
	border-width: 2px;
	padding-top: 13px;
	padding-bottom: 13px;
	outline: 0 none;
	box-shadow: none;
}
.sn-img-lang-wrapper button {
	background-color: transparent;
	border: none;
	margin-left: 12px;
}
.sn-img-lang-wrapper button:hover {
	background-color: #266585;
	border: 1px solid #2562EC;
	color: #fff;
	border-radius: 24px;
	padding: 0 8px;
}

@media (max-width: 560px) {
	#plans-list{
		padding-left: 12px;
		padding-right: 12px;
	}
	.best-offers-save-wrapper {
		max-width: 100%;
	}
	.plan-info-group{
		align-items: flex-start;
	}

	.plan-options{
		width: 130px;
	}

	.plan-options button{
		font-size: 12px;
		padding-left: 20px;
		padding-right: 20px;
	}

	.rep-logo{
		width: 65px;
		height: 33px;
	}

	.rep-logo i{
		margin-top: 4px;
		font-size: 10px;
	}

	.plan-info>div{
		font-size: 16px;
	}

	.plan-info label{
		font-size: 13px;
		line-height: 1.1;
		margin-top: 6px;
	}

	.plan-options .plan-info label{
		padding: 0 12px;
	}

	.plan-info .plan-est-rate{
		font-size: 17px;
	}

	.plan-row-wrapper{
		margin-left: -4px;
		margin-right: -4px;
	}

	.plan-row{
		padding: 0 12px;
	}

	.plan-intro{
		padding-left: 75px;
	}

	.nav-tabs .nav-link{
		font-size: 14px;
	}

	.plan-provider-contact{
		font-size: 14px;
		margin-top: 12px;
	}

	.plan-info.plan-info-terms span{
		font-size: 12px;
	}

	.modal-body{
		padding-left: 16px;
		padding-right: 16px;
	}
	.nav-list li {
		width: 50%;
	}
	.content-section {
		padding: 36px;
	}
	.content-best-offers-section p {
		font-size: 14px;
	}
}
@media (max-width: 750px) {
	#plans-wrapper{
		flex-direction: column;
		padding-top: 0;
	}
	#plans-aside{
		width: 100%;
		max-width: 100%;
		padding-left: 20px;
		padding-right: 20px;
	}

	.side-modal-body{
		width: 100%;
		padding-left: 20px;
		padding-right: 20px;
	}

	#plans-filter-toggle{
		display: inline-block;
		flex: 1;
	}

	#plans-sort-toggle{
		flex: 1;
	}

	#plans-sort-toggle .dropdown-toggle{
		width: 100%;
	}

	#plans-filter-group{
		display: none;
		margin-top: 0;
	}

	#plans-filter-group.show{
		position: fixed;
		display: block;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #fff;
		z-index: 10;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
		padding: 88px 20px 44px;
	}

	#plans-filter-group.show .close-btn{
		display: block;
	}

	#plans-list-header{
		flex-direction: column;
	}

	#term-filter-wrapper{
		margin-right: 0;
		margin-bottom: 12px;
		width: 100%;
	}

	#term-filter-wrapper .plans-filter-options,
	#term-filter-wrapper .plans-filter-dd,
	#term-filter-wrapper .plans-filter-dd button{
		width: 100%;
	}
	
	#term-filter-wrapper .plans-filter-dd button{
		text-align: center;
	}
}
@media (max-width: 767px) {
	#autocomplete-wrapper {
		width: 100%;
	}
	.sn-rep-list-section div img {
		height: 100% !important;
	}
}

@media (min-width: 767px) {
	input.form-control {
		font-size: 14px;
	}
	#autocomplete-wrapper input{
		font-size: 16px;
	}
	.sn-rep-list-wrapper > div {
		display: flex;
		justify-content: space-between;
		flex-direction: row;
	}
	.sn-rep-list-section {
		flex: 0 70%;
		display: flex;
		justify-content: space-between;
		margin: 32px auto;
		flex-direction: row;
	}
	.nav-list {
		padding: 0;
	}
	.nav-list li {
		margin: 24px auto;
		width: auto;
		display: block;
	}
	.best-offers-save-wrapper {
		padding: 44px 0 32px 0;
	}
	.best-offers-wrapper {
		display: flex;
		flex-direction: row;
		min-height:400px;
	}
	.best-offers-save-wrapper h2 {
		font-size: 3rem;
		max-width: 100%;
	}
	.best-offers-images {
		position: absolute;
		width: 50px; /* Adjust figure size as needed */
		height: 50px; /* Adjust figure size as needed */
		border-radius: 50%;
		top: -24px;
	}
	.best-offers-images.top img {
		filter: drop-shadow(0px 5px 3px);
	}
	.best-offers-images:nth-child(2) {
		top: 29%;
		left: 144px;
		width: 130px;
		display: inline-block;
	}
	.best-offers-images:nth-child(2) img {
		filter: drop-shadow(4px 4px 4px);
	}

	.best-offers-images:nth-child(3) {
		top: 40%;
		width: 170px;
		left: -42px;
		display: inline-block;
	}
	.best-offers-images:nth-child(3) img {
		filter: drop-shadow(4px 5px 7px);
	}
	.best-offers-images.top {
		position: absolute;
		height: 50px;
		border-radius: 50%;
		top: -24px;
		width: 290px;
	}
	.best-offers-main-wrapper {
		background: linear-gradient(to right, #eea640 72%, #ffffff 28%);
	}
	.img-best-offers-section {
		flex: 0 35%;
		position: relative;
		height: 490px;
	}
	.sn-pages-list {
		flex: 0 30%;
	}
	.content-best-offers-section {
		padding: 58px 32px 24px 0;
		line-height: 2rem;
		flex: 0 60%;
	}
	.content-best-offers-section p {
		font-size: 18px;
		max-width: 750px;
		color: #3d2d21;
	}
	.content-section {
		padding: 24px;
		border-radius: 24px;
		height: 350px;
		color: #121518;
		flex: 0 75%;
	}
	.content-section.margin-right {
		margin-right: 26px;
	}
	.content-section.margin-left {
		margin-left: 12px;
	}
	.more-strategies-form-wrapper {
		max-width: 555px;
	}
	.contact-us-text {
		max-width: 100%;
	}
	.contact-us-text p {
		font-size: 24px;
	}
	.sn-rep-list-section div img {
		width: 90%;
		margin-bottom: 24px;
		height: 100%;
	}
	.sn-rep-list-section div {
		margin-bottom: 12px;
	}


}
@media (min-width: 992px) {
	.address-search-wrapper {
		display: flex;
		justify-content: center;
	}
	.address-search-wrapper button {
		margin-top: 0;
	}
	.more-strategies-form-wrapper {
		padding: 48px 0 24px 0;
	}
}
@media (max-width: 750px) {
	.autocomplete-address-row{
		flex-direction: column;
		margin-right: 12px;
	}

}

@media (min-width: 1170px) {
	.autocomplete-address-row{
		flex-direction: column;
		margin-right: 12px;
	}

	.utility-select{
		margin-left: 0;
		margin-top: 12px;
		flex: 1;
		width: 100%;
	}

	.utility-select .dropdown-toggle, .utility-select .dropdown-menu{
		width: 100%;
	}

	.plan-overview{
		flex-direction: column-reverse;
		align-items: flex-start;
	}

	.plan-options{
		width: 180px;
	}

	.plan-info-group{
		width: 100%;
	}

	.plan-info-group .plan-info{
		text-align: left;
		padding-left: 4px;
	}

	.plan-intro{
		padding-left: 84px;
		margin-top: -54px;
	}
}

@media (min-width: 1200px) {
	.container {
		max-width: 1140px;
	}
	.content-section {
		padding: 36px;
		max-width: 640px;
		height: 370px;
	}
	.content-section .read-more-links {
		margin-top: 14px;
		text-align: right;
		padding: 0;
	}
	.content-section.margin-left {
		margin-left: 30px;
	}
	.best-offers-main-wrapper {
		background: linear-gradient(to right, #eea640 60%, #ffffff 40%);
	}
	.best-offers-images.top {
		width: 400px;
	}

	.best-offers-images:nth-child(2) {
		top: 42%;
		left: 194px;
		width: 155px;
	}
	.best-offers-images:nth-child(3) {
		top: 55%;
		width: 243px;
		left: -71px;
	}
	.contact-us-text {
		margin-top: 32px;
	}
	.validate-address-section h2{
		font-size: 1.5rem;
	}
	.content-best-offers-section {
		padding: 58px 92px 24px 0;
	}
	.content-best-offers-section p {
		font-size: 22px;
	}
	.best-offers-save-wrapper h2 {
		max-width: 100%;
	}
}
@media (min-width: 1400px) {
	.container {
		max-width: 1320px;
	}

}
@media (min-width: 1600px) {
	.best-offers-main-wrapper {
		background: linear-gradient(to right, #eea640 58%, #ffffff 42%);
	}
}

@media (min-width: 2200px) {
	.best-offers-main-wrapper {
		background: linear-gradient(to right, #eea640 56%, #ffffff 44%);
	}
}
